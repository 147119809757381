import React, { useContext, useEffect, useState, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import socket from "./util/io";
import { AuthProvider, AuthContext } from "./AuthContext";
import NotificationCard from "./components/Notification";
import ErrorBoundary from "./components/ErrorBoundary";

import "react-datepicker/dist/react-datepicker.css";

// Lazy load route components
const Signin = React.lazy(() => import("./components/Signin/Signin"));
const Admin = React.lazy(() => import("./components/Admin/Admin"));
const UserA = React.lazy(() => import("./components/UserA/UserA"));
const UserB = React.lazy(() => import("./components/UserB/UserB"));
const UserC = React.lazy(() => import("./components/UserC/UserC"));
const UserD = React.lazy(() => import("./components/UserD/UserD"));

function LoadingFallback() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
    </div>
  );
}

function App() {
  const authContext = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [socketError, setSocketError] = useState(false);

  useEffect(() => {
    const handleSocketError = (error) => {
      console.error("Socket error:", error);
      setSocketError(true);
    };

    const handleSocketConnect = () => {
      setSocketError(false);
    };

    // socket.on("connect_error", handleSocketError);
    // socket.on("connect", handleSocketConnect);
    socket.on("notification", (data) => {
      try {
        setNotification({
          body: data?.body || "No content",
          createdAt: data?.createdAt || new Date().toISOString(),
          link: data?.link || "#",
          _id: data?._id,
        });
      } catch (error) {
        console.error("Notification processing error:", error);
      }
    });

    return () => {
      // socket.off("connect_error", handleSocketError);
      // socket.off("connect", handleSocketConnect);
      socket.off("notification");
    };
  }, []);

  const getCurrentRoute = () => {
    const { auth } = authContext;
    if (!auth) return <Navigate to="/" replace />;

    if (auth.admin) return <Admin />;
    if (auth.usera) return <UserA />;
    if (auth.userb) return <UserB />;
    if (auth.userc) return <UserC />;
    if (auth.userd) return <UserD />;
    return <Signin />;
  };

  return (
    <ErrorBoundary>
      <div className="relative">
        <AnimatePresence>
          {notification && (
            <motion.div
              className="z-50 max-w-md w-full fixed top-20 right-4"
              initial={{ opacity: 0, x: 500 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 500 }}
              transition={{ duration: 0.35, type: "spring" }}
            >
              <NotificationCard
                realtime
                notification={notification}
                setNotification={setNotification}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {socketError && (
          <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
            Connection lost. Attempting to reconnect...
          </div>
        )}

        <BrowserRouter>
          <Suspense fallback={<LoadingFallback />}>
            <Routes>
              <Route path="/*" element={getCurrentRoute()} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
}

function AppWithStore() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default AppWithStore;
